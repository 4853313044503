<template>
  <div class="upload_container required err_message_box">
    <p>
      <span class="txt-red txt-regular" v-if="item.required">*</span>
      {{ item.label }} <span class="txt-regular" v-show="item.reminder">
      ({{ item.reminder }})</span>
    </p>
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <input
      :id="id"
      :rules="rules"
      type="file"
      :error="error"
      :accept="item.accept"
      @change="test(); fileChangeHandler($event);"
    >
    <label :for="id">
      <p class="upload_hint">
        <span v-show="fileName">
          {{ fileName }}
        </span>
        <span v-show="!fileName">
          請選擇檔案
        </span>
      </p>
      <p class="upload_btn">
        <span>選擇檔案</span>
      </p>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FormUpload',
  props: {
    value: {},
    rules: Array,
    item: {},
  },
  data() {
    return {
      errorMsg: '',
      id: null,
      fileName: '',
    };
  },
  watch: {
    value(v) {
      this.errorMsg = this.validate(v) ? this.validate(v) : '';
    },
  },
  computed: {
    hasRules() {
      return this.rules !== undefined
        && this.rules !== null
        && this.rules.length > 0;
    },
    error() {
      if (this.hasRules && this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    test() {
      this.errorMsg = this.validate(this.fileName) ? this.validate(this.fileName) : '';
    },
    fileChangeHandler(e) {
      // console.log(e);
      if (e.target.files.length > 0) {
        this.$emit('input', e.target.files[0]);
        this.fileName = e.target.files[0].name;
      } else {
        this.$emit('input', null);
        this.fileName = '';
      }
    },
    validate(val = this.fileName) {
      if (this.hasRules === true) {
        const errorMessage = [];
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            if (rule(val) !== true) {
              errorMessage.push(rule(val));
            }
          } else if (typeof rule === 'object') {
            if (!val.match(rule[0])) {
              errorMessage.push(rule[1]);
            }
          }
        });
        if (errorMessage.length) {
          return errorMessage[0];
        }
      }
      return '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
